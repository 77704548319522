import {
	SELECTED_PAGE,
	SELECTED_PAGE_CONTENT,
	SELECTED_WEBSITE,
	TOTAL_PAGES,
	BOOKING_NAV,
	DEFAULT_HEADER,
	DEFAULT_FOOTER,
} from './WebsiteBuilderActionTypes';

interface IBuilder {
	selectedPage: any;
	selectedWebsite: any;
	selectedPageContent: any;
	totalPages: [];
	bookingNavigation: {};
	defaultHeader: {};
}

const initialState: IBuilder = {
	selectedPage: {},
	selectedPageContent: {},
	selectedWebsite: {},
	totalPages: [],
	bookingNavigation: {},
	defaultHeader: {},
};

const WebsiteBuilder = (state: any = initialState, action): IBuilder => {
	switch (action.type) {
		case SELECTED_WEBSITE:
			return { ...state, selectedWebsite: action.payload };
		case SELECTED_PAGE:
			return { ...state, selectedPage: action.payload };

		case SELECTED_PAGE_CONTENT:
			return { ...state, selectedPageContent: action.payload };

		case TOTAL_PAGES:
			return { ...state, totalPages: action.payload };

		case BOOKING_NAV:
			return { ...state, bookingNavigation: action.payload };
		case DEFAULT_HEADER:
			return { ...state, selectedDefaultHeader: action.payload };

		case DEFAULT_FOOTER:
			return { ...state, selectedDefaultFooter: action.payload };

		default:
			return state;
	}
};

export default WebsiteBuilder;
