import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'storybook-mui/build/components/Button';
import Modal from 'storybook-mui/build/components/Modal';
import { SelectField } from 'storybook-mui/build/components/Select/Select';
import InputTextField from 'storybook-mui/build/components/TextField';
import TextArea from 'storybook-mui/build/components/MuiTextArea';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import { RootStore } from 'reducers';
import {
	GetEmailSenderProfiles,
	saveCreativeInCampaign,
	saveCampaign,
} from 'firebaseApis/single_channel_campaigns';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import {
	getOrganisationIdFirebase,
	getUserId,
	getUserName,
	getXProjKey,
} from 'api/AxiosManager';
import { processEmailCampaign } from 'api/Campaign';
import { Timestamp } from 'firebase/firestore';
import Toast, { ToastTypes } from 'components/ToastNotify';
import {
	CreateOrganisation,
	GetOrganisationInfoById,
} from 'firebaseApis/authentication';
import { INewOrgInitialState } from 'reducers/CreateOrg';
import LinearProgressBar from 'components/LinearProgressBar';
import {
	CancelNewOrgFlow,
	SetNewOrgFlowStep,
} from 'reducers/CreateOrg/CreateOrgActions';
import { increaseProgressVal } from 'reducers/WelcomeProgressbar';
import { GetEmailCreativeByName } from 'firebaseApis/email_creatives';

const SendPaymentReqModal = ({
	isShowModal,
	changePaymentReqModalHandler,
}: ISendPaymentReqModal) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [currentProgress, setCurrentProgress] = useState<number>(0);
	const dispatch = useDispatch();
	const { senderProfiles } = useSelector(
		(state: RootStore) => state.newCampaignStore
	);
	const newOrgData: INewOrgInitialState = useSelector(
		(state: any) => state.createOrgReducer
	);
	const [senderProfileOptions, setSenderProfileOptions] = useState<any[]>([]);

	const saveCampaignLocal = async (values) => {
		setLoading(true);
		values.senderProfile = senderProfileOptions.find(
			(sp) => sp.sender_profile === values.senderProfile
		);
		const payload = {
			Approval_Status: 'N/A',
			BCC: [],
			CC: [],
			Campaign_Approver: {
				Owner_Name: '',
				User_Doc_Id: '',
			},
			Campaign_Created_By: {
				Date_And_Time: Timestamp.now(),
				Owner_Name: getUserName(),
				User_Doc_Id: getUserId(),
			},
			Campaign_Last_Modified_By: {
				Date_And_Time: Timestamp.now(),
				Owner_Name: getUserName(),
				User_Doc_Id: getUserId(),
			},
			Campaign_Name: 'Payment Request',
			Campaign_Owner: {
				Owner_Name: '',
				User_Doc_Id: '',
			},
			Campaign_Run_Type: 'runNow',
			Campaign_Status: 'Yet to start',
			Campaign_Type: 'Email',
			Description: values.message,
			Is_Canceled: false,
			Is_Processed: true,
			Is_Read: false,
			Job_ID: '',
			Last_Run_Date_Time: Timestamp.now(),
			No_Of_Total_Runs_Till_Now: 0,
			Notify_Others: [],
			Organization_Id: getOrganisationIdFirebase(),
			Pick_Segment_Filters_From_Campaign_Object: false,
			Recurring: {},
			Schedule: {},
			Schedule_Details: {},
			Segment: {},
			Segment_Type: '',
			Sender_Profile: {
				ApiKey: values.senderProfile.APIKey || '',
				Email: values.senderProfile.Email || '',
				Marketing_Campaigns: true,
				MessagingServiceID: '',
				Name: values.senderProfile.sender_profile || '',
				PhoneNumbers: '',
				PostText: '',
				PostVoice: '',
				PreText: '',
				PreVoice: '',
				ReplyToEmail: values.senderProfile.reply_to || '',
				ReplyToName: values.senderProfile.reply_to_name || '',
				SenderEmailName: values.senderProfile.sender_name || '',
			},
			Social_Accounts: [],
			Social_Info: [],
			Subject: values.subjectLine,
			Task_ID: '',
			Text: '',
			Unix_Timestamp: moment().unix(),
			X_Project_Key: getXProjKey(),
			hasDynamicHTMLVariable: false,
			plain_contentHaveDynamicVariable: false,
			subjectHaveDynamicVariable: false,
			Pick_Contact_List_From_Campaign_Object: true,
			Contact_List: [
				{
					name: `${values.firstName} ${values.lastName}`,
					email: values.email,
					phone_number: '',
					contact_id: moment().valueOf(),
				},
			],
		};
		try {
			const response: any = await saveCampaign(payload);
			const campaignId = response.id;
			const creativeToSend = await GetEmailCreativeByName('Payment Reminder');
			await saveCreativeInCampaign({
				id: campaignId,
				Creative: {
					...creativeToSend,
				},
			});
			await processEmailCampaign(campaignId);
			Toast({
				title: 'Payment Request Sent Successfully',
				type: ToastTypes.SUCCESS,
			});
			const response2 = await CreateOrganisation({
				newOrgData,
				paymentInfo: null,
				cardValues: null,
			});
			if (response2.data && response2.data?.Organization_Id) {
				setLoading(false);
				Toast({
					title: 'Sub Organization Created Successfully',
					type: ToastTypes.SUCCESS,
				});
				setCurrentProgress(100);
				dispatch(increaseProgressVal(10));
				await GetOrganisationInfoById(response2.data?.Organization_Id);
				dispatch(SetNewOrgFlowStep(newOrgData.Step + 2));
			} else {
				setLoading(false);
				setCurrentProgress(0);
				Toast({
					title: 'Sub Organization Creation Failed',
					type: ToastTypes.ERROR,
				});
			}
		} catch (error) {
			setLoading(false);
			Toast({ title: 'Payment Request Failed', type: ToastTypes.SUCCESS });
		}
	};

	const paymentRequestFormik = useFormik({
		initialValues: {
			fullname: '',
			email: '',
			subjectLine: '',
			message: '',
			senderProfile: '',
		},
		validationSchema: Yup.object().shape({
			fullname: Yup.string()
				.required('Required')
				.min(2, 'Invalid Name')
				.max(50, 'Invalid Name'),
			email: Yup.string().required('Required').email('Invalid email'),
			subjectLine: Yup.string().required('Required').min(2, 'Invalid Subject'),
		}),
		validateOnChange: true,
		onSubmit: async (values) => {
			saveCampaignLocal(values);
		},
	});

	useEffect(() => {
		if (senderProfiles.length) {
			setSenderProfileOptions(senderProfiles);
		} else {
			dispatch(showLoading());
			GetEmailSenderProfiles().then((result: any) => {
				setSenderProfileOptions(result);
				dispatch(hideLoading());
			});
		}
	}, []);
	const onProgressComplete = async () => {
		await new Promise((resolve) => setTimeout(resolve, 300));
		setLoading(false);
		setCurrentProgress(0);
		dispatch(CancelNewOrgFlow());
	};
	if (loading) {
		return (
			<LinearProgressBar
				delay={50000}
				incrementBy={1}
				currentProgress={currentProgress}
				onProgressComplete={onProgressComplete}
				message='Please be patient while we setup your Business/Organization. This may take a couple of minutes...'
			/>
		);
	}

	return (
		<Modal
			size='md'
			modalId='paymentRequest-modal'
			open={isShowModal}
			setOpen={() => changePaymentReqModalHandler(!isShowModal)}
			closeIcon
			title='SEND PAYMENT REQUEST'
			content={
				<div className='flex flex-col gap-3 pt-4'>
					<div>
						<p className='text-xl text-gray-800'>
							This will allow you to request someone else from your company to complete
							billing info.
						</p>
					</div>

					<form
						className='flex gap-4 flex-col'
						onSubmit={paymentRequestFormik.handleSubmit}
					>
						<div className='flex flex-col gap-5 w-96'>
							<div>
								<SelectField
									size='medium'
									placeholder='Sender Profile'
									value={paymentRequestFormik.values?.senderProfile}
									options={senderProfileOptions}
									name='senderprofile'
									fieldId='senderprofile'
									displayField='sender_profile'
									valueField='sender_profile'
									onChange={(e) => {
										paymentRequestFormik.setFieldValue('senderProfile', e.target.value);
									}}
								/>
							</div>
						</div>
						<div className='flex flex-col gap-5'>
							<div className='flex'>
								<div className='w-2/4 mr-4'>
									<InputTextField
										name='fullname'
										size='medium'
										value={paymentRequestFormik.values.fullname}
										labelName='Full Name'
										fieldId='fullname'
										errorMessage={paymentRequestFormik.errors.fullname}
										valid={paymentRequestFormik.errors.fullname === undefined}
										onChange={paymentRequestFormik.handleChange}
									/>
								</div>
								<div className='w-2/4 ml-4'>
									<InputTextField
										name='email'
										size='medium'
										value={paymentRequestFormik.values.email}
										labelName='Email Address'
										fieldId='emailId'
										errorMessage={paymentRequestFormik.errors.email}
										valid={paymentRequestFormik.errors.email === undefined}
										onChange={paymentRequestFormik.handleChange}
									/>
								</div>
							</div>
							<div>
								<InputTextField
									name='subjectLine'
									size='medium'
									value={paymentRequestFormik.values.subjectLine}
									labelName='Subject Line'
									fieldId='subjectLine'
									placeholder='Send a note explaining the request.'
									errorMessage={paymentRequestFormik.errors.subjectLine}
									valid={paymentRequestFormik.errors.subjectLine === undefined}
									onChange={paymentRequestFormik.handleChange}
								/>
							</div>
							<div>
								<TextArea
									name='message'
									fieldId='message'
									labelName='Mesage for non HTML clients'
									placeholder='Enter text to deliver to email clients that do not support HTML emails'
									onChange={paymentRequestFormik.handleChange}
									value={paymentRequestFormik.values.message}
									rows={2}
								/>
							</div>
							<div className='flex items-center justify-end space-x-5 mr-4'>
								<Button
									title='Cancel'
									variant='outlined'
									color='inherit'
									onClick={() => changePaymentReqModalHandler(false)}
								/>
								<Button title='Next' type='submit' variant='contained' />
							</div>
						</div>
					</form>
				</div>
			}
		/>
	);
};

export default SendPaymentReqModal;

export interface ISendPaymentReqModal {
	isShowModal: boolean;
	changePaymentReqModalHandler: any;
}
