import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Drawer from '@mui/material/Drawer';

import { SetAuthenticationFlowView } from 'reducers/Authentication/AuthActions';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';
import IconButtonComponent from 'storybook-mui/build/components/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from 'reducers';
import { getOrganisationIdFirebase, getUser } from 'api/AxiosManager';
import Leap360Logo from 'assets/img/leap360.png';

import {
	GetCampaignsUnReadCount,
	GetReceivedCount,
} from 'firebaseApis/socialmedia';
import { ReloadLayout } from 'reducers/Layout/LayoutActions';
import BadgeComponent from 'storybook-mui/build/components/Badge';
import { GetOrganisationDetailsById } from 'firebaseApis/organization';
import { Spinner } from 'components/Spinner';
import { GetSidebarMenu } from 'firebaseApis/dynamic';
import Toast, { ToastTypes } from 'components/ToastNotify';
import NavPill, { INavItem, ISubItem } from './NavPill';

export const Nav = ({ sidebarOpen, setSidebarOpen }: INav) => {
	const [dynamicMenu, setDynamicMenu] = useState<INavItem[]>([]);
	const dispatch = useDispatch();
	const [organizationData, setOrganizationData] = useState<any>(null);
	const user = getUser();
	const layoutStore = useSelector((state: RootStore) => state.layoutReducer);
	const history = useHistory();
	const [openTab, setOpenTab] = useState<string>(null);
	const [count, setCount] = useState<number>(0);
	const [campaignCount, setCampaignCount] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const collapse = useSelector(
		(state: RootStore) => state.layoutReducer
	).Collapse;
	const headerNav = useSelector(
		(state: RootStore) => state.layoutReducer
	).HeaderNav;

	const onLogoClick = () => {
		if (user.organizationPath) {
			history.push('/Dashboard');
		} else {
			history.push('/createorg');
			dispatch(SetAuthenticationFlowView(0));
		}
	};
	const getCounts = async () => {
		const socialPostsCount = await GetReceivedCount(getUser()?.userId);
		const pendingCount = await GetCampaignsUnReadCount(getUser()?.userId);
		setCampaignCount(pendingCount ?? 0);
		setCount(socialPostsCount ?? 0);
	};
	const getData = async () => {
		try {
			const response = await GetOrganisationDetailsById(
				getOrganisationIdFirebase()
			);
			setOrganizationData(response);
			dispatch(ReloadLayout(false));
			return response;
		} catch (error) {
			dispatch(ReloadLayout(false));
			return error;
		}
	};
	useEffect(() => {
		if (layoutStore.Reload_Layout === true) {
			if (!user || Object.keys(user).length === 0) {
				history.push('/');
			} else {
				getCounts();
				getData();
			}
		}
	}, [layoutStore.Reload_Layout]);

	const getSidebarMenu = async () => {
		try {
			setLoading(true);
			const result = await GetSidebarMenu();
			const tempSidebarMenu = result.menu;
			const communicationSubItem = {
				name: "Communication",
				href: "/Inbox/Communication"
			}
			tempSidebarMenu.forEach((item: INavItem, mainMenuIndex: any) => {
				if (item.name === 'Inbox') {
					item.subItems.forEach((subItem: ISubItem, subItemIndex: any) => {
						if (subItem.name === 'Campaigns') {
							tempSidebarMenu[mainMenuIndex].subItems[subItemIndex] = {
								name: (
									<BadgeComponent
										content='Campaigns'
										id='campaigns-notification-count'
										count={campaignCount}
									/>
								),
								href: '/Inbox/Campaigns/ApprovalPending',
							};
						}
						if (subItem.name === 'Social') {
							tempSidebarMenu[mainMenuIndex].subItems[subItemIndex] = {
								name: (
									<BadgeComponent
										content='Social'
										id='social-notification-count'
										count={count}
									/>
								),
								href: '/Inbox/Social/Received',
							};
						}
					});
					tempSidebarMenu[mainMenuIndex].subItems.push(communicationSubItem);
				}

				if (item.name === 'Admin') {
					item.subItems.forEach((subItem: ISubItem, subItemIndex: any) => {
						if (subItem.name === 'Organization') {
							tempSidebarMenu[mainMenuIndex].subItems[subItemIndex] = {
								name: user?.organizationName,
								href: '/Admin/Organization/DefaultOrg',
							};
						}
					});
				}
			});
			dispatch(ReloadLayout(false));
			setDynamicMenu(tempSidebarMenu);
			setLoading(false);
		} catch (error) {
			dispatch(ReloadLayout(false));
			setLoading(false);
			Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
		}
	};

	useEffect(() => {
		if (layoutStore.Reload_Layout || dynamicMenu.length === 0) {
			getSidebarMenu();
		}
	}, [layoutStore.Reload_Layout]);

	return (
		<>
			{loading && <Spinner />}
			{headerNav ? (
				<>
					<Drawer
						sx={{
							flexShrink: 0,
							'& .MuiDrawer-paper': {
								boxSizing: 'border-box',
							},
						}}
						anchor='left'
						open={sidebarOpen}
						onClose={() => setSidebarOpen(false)}
					>
						<div className='relative flex-1 flex flex-col max-w-xs w-72 bg-white'>
							<div className='flex items-center mt-2 ml-4'>
								<IconButtonComponent
									id='drawer-close'
									ariaLabel='drawer-close'
									onClick={() => setSidebarOpen(false)}
									size='large'
									content={<Icon icon={IconTypes.Cancel} />}
								/>
								<div className='cursor-pointer' onClick={onLogoClick}>
									<div className='flex items-center justify-center flex-col'>
										{organizationData?.Logo_Type === 'CUSTOM_LOGO' ? (
											<div className='w-28'>
												<img
													src={organizationData.Logo_Url}
													className='inline w-full h-12'
												/>
											</div>
										) : organizationData?.Logo_Type === 'ORGANIZATION NAME' ? (
											<h1 className='text-xl font-semibold text-gray-500 w-56 truncate'>
												{organizationData?.Org_Name ?? 'LEAP360'}
											</h1>
										) : (
											<div className='w-28'>
												<img src={Leap360Logo} className='inline w-full h-12' />
											</div>
										)}
									</div>
								</div>
							</div>

							<div className='flex-1 flex flex-col pt-2 pb-4 overflow-y-auto'>
								<nav className='flex-1 flex flex-col justify-between mt-2 pr-4 relative z-10'>
									<div>
										{dynamicMenu.map((item, index) => (
											<NavPill
												item={item}
												key={index}
												openTab={openTab}
												setOpenTab={setOpenTab}
											/>
										))}
									</div>
								</nav>
							</div>
						</div>
					</Drawer>

					<div className='hidden md:flex md:flex-shrink-0'>
						<div className={`flex flex-col ${collapse ? `w-18` : `w-auto`}`}>
							<div className='flex-1 relative flex flex-col min-h-0 bg-gray-lightest'>
								<div className='mobile-screen items-start flex flex-col pt-2 pb-4 overflow-y-auto h-screen'>
									{/* fixed class has to be added on scroll */}
									<nav className='mt-4 lg:mt-24 flex-1 flex flex-col justify-start'>
										{dynamicMenu.map((item, index) => (
											<NavPill
												item={item}
												key={index}
												openTab={openTab}
												setOpenTab={setOpenTab}
											/>
										))}
									</nav>
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				''
			)}
		</>
	);
};

export interface INav {
	sidebarOpen: boolean;
	setSidebarOpen: any;
}

export default Nav;
