import {
	createWithXProjKey,
	getClientDB,
	getOrganisationIdFirebase,
	getUserName,
} from 'api/AxiosManager';
import CryptoJS from 'crypto-js';
import {
	addDoc,
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	query,
	setDoc,
	where,
} from 'firebase/firestore';
import firebase from 'firebase/compat';
import { masterDb } from 'firebaseConfig';
import moment from 'moment';

export const AddExistingUserToOrganisation = async (
	userId: string,
	orgId,
	orgName,
	firestoreConfig: any
) => {
	const localStorageData = JSON.parse(localStorage.getItem('user'));

	const organizationId = orgId !== '' ? orgId : getOrganisationIdFirebase();
	let clientDB: any;
	if (firestoreConfig) {
		const clientApp = firebase.initializeApp(firestoreConfig, orgId);
		clientDB = clientApp.firestore();
	} else {
		clientDB = getClientDB();
	}

	try {
		let result: any = {};
		const userInClient = await getDoc(
			doc(clientDB, `${organizationId}/Users/Users`, userId)
		);
		if (userInClient.exists()) {
			result = {
				success: false,
				message: 'User already exists in organisation',
			};
		} else {
			const userInMaster = await getDoc(doc(masterDb, `Users/${userId}`));
			if (userInMaster.exists()) {
				// adding data to users mapping in master
				const payloadForUsersMappingInMaster = {
					Created_By_User_Id: orgId !== '' ? userId : localStorageData.userId,
					Created_Date_Time_Gmt: moment().toString(),
					IS_Accept: true,
					IS_Accept_Invitation: true,
					Last_Modified_By_User_Id: orgId !== '' ? userId : localStorageData.userId,
					Last_Modified_Date_Time_Gmt: moment().toString(),
					Org_Name: orgName !== '' ? orgName : localStorageData?.organizationName,
					Organization_Id: organizationId,
					User_Id: userId,
				};
				await addDoc(
					collection(masterDb, `UsersMapping`),
					payloadForUsersMappingInMaster
				);

				// adding data to users in client

				const payloadForUsersInClient = {
					Created_By: getUserName(),
					Created_On: moment().toString(),
					Address_Line1: null,
					Address_Line2: null,
					Address_Line3: null,
					Auto_Unlock_Date_Time: null,
					City: null,
					Code2fa_Expires: null,
					Code_2fa: null,
					Code_2fa_Sent: null,
					Country_Id: null,
					Country_State: null,
					County: null,
					Display: true,
					Email: userInMaster.data().Email,
					Email_2fa: null,
					FirstName: userInMaster.data().FirstName,
					Is_2fa_Enabled: false,
					Is_Cancelled: false,
					Is_Deleted: false,
					Is_Forgotten: false,
					Is_Locked: false,
					Is_Suspended: false,
					LastName: userInMaster.data().LastName,
					Locked_Date_Time: null,
					Name: userInMaster.data().Name,
					Next_Passcode_Expire_Date_Time: null,
					Notifications_By_Email: false,
					Notifications_By_Sms: false,
					Notifications_By_Voice: false,
					Passcode_Expired_Date_Time: null,
					Password: '',
					Phone_2fa: null,
					Phone_2fa_Country_Code: null,
					Provider: '',
					Send_2fa_2_Email: false,
					Send_2fa_2_Phone_Sms: false,
					Send_2fa_2_Phone_Voice: false,
					User_Id: userId,
					Verified_Email_2fa: null,
					Verified_Phone_2fa: null,
					Zip: null,
				};
				await setDoc(
					doc(clientDB, `${organizationId}/Users/Users`, userId),
					payloadForUsersInClient
				);

				result = {
					success: true,
					message: 'User added to organisation',
				};
			} else {
				result = {
					success: false,
					message: 'User does not exist',
				};
			}
		}
		return result;
	} catch (error) {
		return {
			success: false,
			message: error.message,
		};
	}
};

export const SendInvitationToUser = async (email) => {
	let result;
	const user = JSON.parse(localStorage.getItem('user'));
	const tokenObj = {
		organizationId: getOrganisationIdFirebase(),
		organizationName: user.organizationName,
		clientDb: user.firestoreConfig,
		email,
	};

	const encryptedToken = CryptoJS.AES.encrypt(
		JSON.stringify(tokenObj),
		'PPCRM'
	).toString();

	const existingUsersSnapshots = await getDocs(
		query(
			collection(masterDb, `Users`),
			where('Email', '==', email.toLowerCase())
		)
	);

	if (existingUsersSnapshots.size > 0) {
		result = {
			success: false,
			message: 'User already exists',
		};
	} else {
		const redirectUrl = `${window.location.origin}/join?token=${encryptedToken}`;
		try {
			const payload = {
				emailObj: {
					to: [
						{
							email,
						},
					],
					from: {
						email: 'support@leap360.com',
						name: 'Leap360',
					},
					bcc: [],
					subject: 'Invitation to join the organization',
					text: 'and easy to do anywhere, even with Node.js testing',
					html: `<h1>Hi , You got an invitation from ${user.organizationName} </h1> <hr/> <p>Please click on the link below to join the ${user.organizationName} organisation</p><p><a href=${redirectUrl}>ACCEPT INVITATION</a></p>`,
				},

				organization_id: '1617647525093',
				sender_profile: '',
			};
			const instance = createWithXProjKey();
			await instance.post(`/sendgridEmail/sendEmail`, payload);
			result = {
				success: true,
				message: 'Invitation sent successfully',
			};
		} catch (error) {
			result = {
				success: true,
				message: 'Something went wrong',
			};
		}
	}
	return result;
};

export const GetAllUsers = async () => {
	try {
		const qry = collection(
			getClientDB(),
			`${getOrganisationIdFirebase()}/Users/Users`
		);

		const resultSnapshots: any = await getDocs(qry);

		const resultArray = resultSnapshots.docs.map((document: any) => ({
			...document.data(),
			id: document.id,
		}));

		return {
			data: resultArray.filter((item) => item?.Display !== false),
		};
	} catch (error) {
		return error;
	}
};

export const DeleteUserFromOrganisation = async (userId: string) => {
	try {
		await deleteDoc(
			doc(getClientDB(), `${getOrganisationIdFirebase()}/Users/Users/${userId}`)
		);
		const userMappingSnapshots = await getDocs(
			query(
				collection(masterDb, `UsersMapping`),
				where('User_Id', '==', userId),
				where('Organization_Id', '==', getOrganisationIdFirebase())
			)
		);
		const userMappingArray = userMappingSnapshots.docs.map((document: any) => ({
			...document.data(),
			id: document.id,
		}));
		await deleteDoc(doc(masterDb, `UsersMapping/${userMappingArray[0].id}`));
		return true;
	} catch (error) {
		return error;
	}
};
