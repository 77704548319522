export function bytesToSize(bytes, seperator = '') {
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
	if (bytes === 0) return 'n/a';
	const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))), 10);
	if (i === 0) return `${bytes}${seperator}${sizes[i]}`;
	return `${(bytes / 1024 ** i).toFixed(1)}${seperator}${sizes[i]}`;
}

export const blobToFile = (theBlob: Blob, fileName: string): File => {
	const b: any = theBlob;
	// A Blob() is almost a File() - it's just missing the two properties below which we will add
	b.lastModifiedDate = new Date();
	b.name = fileName;
	// Cast to a File() type
	//	return <File>theBlob;
	return new File([theBlob], fileName, { type: theBlob.type });
};

export const urlToFile = (url): any =>
	new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.open('GET', url, true);
		xhr.responseType = 'blob';
		xhr.onload = (e) => {
			if (xhr.status === 200) {
				const myObject = xhr.response;
				const file = blobToFile(myObject, 'test');
				resolve(file);
			} else {
				reject(e);
			}
		};
		xhr.send();
	});

export const checkImageVideo = (url: string): String => {
	if (url?.match(/\.(jpeg|jpg|gif|png)$/) != null) return 'Image';
	if (url?.match(/\.(mp4|mov|wmv|flv|avi|mkv)$/) != null) return 'Video';
	return 'Not valid';
};

export const isValidUrl = (str) => {
	const regexp =
		/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
	if (regexp.test(str)) {
		return true;
	}
	return false;
};

export const getDecimalNumber = (x: any, y: any) => {
	const value = Number(x) / Number(y);
	if (Number(x) % Number(y) === 0) {
		return value;
	}
	if (Number.isNaN(value)) return '0';
	if (!Number.isFinite(value)) return '0';
	return value.toFixed(2);
};

export const getNumberFormat = (value: number) => {
	if (value) {
		return Intl.NumberFormat('en-US').format(value);
	}
	return value;
};

export const searchTree = (element, id) => {
	if (element.id === id) {
		return element;
	}
	if (element.children != null) {
		let result = null;
		for (let i = 0; result === null && i < element.children.length; i++) {
			result = searchTree(element.children[i], id);
		}
		return result;
	}
	return null;
};

export const searchTextInTree = (element, type) => {
	if (element?.response?.find((res) => res.type === type)) {
		return element;
	}
	if (element.children != null) {
		let result = null;
		for (let i = 0; result === null && i < element.children.length; i++) {
			result = searchTextInTree(element.children[i], type);
		}
		return result;
	}
	return null;
};

export const getParentNodeSearchTree = (element, id, parentNode) => {
	if (element.id === id) {
		return parentNode;
	}
	if (element.children != null) {
		let result = null;
		for (let i = 0; result === null && i < element.children.length; i++) {
			result = getParentNodeSearchTree(element.children[i], id, element);
		}
		return result;
	}
	return null;
};

export const getAllNodeIds = ({ id, children }) =>
	children ? [id, ...children.flatMap(getAllNodeIds)] : id;

export const removeNodeFromTree = (element, id) => {
	if (element.id === id) {
		return element;
	}
	if (element.children != null) {
		let result = null;
		for (let i = 0; result === null && i < element.children.length; i++) {
			result = searchTree(element.children[i], id);
			if (result) {
				element.children.splice(i, 1);
			}
		}
		return result;
	}
	return null;
};

export const deleteNodeFromTree = (node, nodeId) => {
	if (node?.children != null) {
		for (let i = 0; i < node.children.length; i++) {
			const filtered = node.children.filter((f) => f.id === nodeId);
			if (filtered && filtered.length > 0) {
				node.children = node.children.filter((f) => f.id !== nodeId);
			}
			deleteNodeFromTree(node.children[i], nodeId);
		}
	}
};

export const removeEmptyNodes = (node): any => {
	if (!node) return;
	// eslint-disable-next-line consistent-return
	return node.reduce((acc, child) => {
		if (child.response.length === 0) {
			const removedChild: any = removeEmptyNodes(child.children);
			// eslint-disable-next-line no-param-reassign
			acc = [...acc, ...removedChild];
		} else {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			child.children = removeEmptyNodes(child.children);
			acc.push(child);
		}
		return acc;
	}, []);
};

export const checkPhoneNumbersValidation = (number) => {
	const regex = /^[\+][0-9]{3}[)]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
	return regex.test(number);
};

export const isJsonString = (str) =>{
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}
