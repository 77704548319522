import {
	SET_DYNAMIC_JSON_TYPE,
	SET_DYNAMIC_JSON,
	SET_DYNAMIC_JSON_VALUES,
	SET_DYNAMIC_JSON_MODULE_NAME,
	RELOAD_FIELD_OPTIONS_LIST,
} from './DynamicActionTypes';

interface IDynamic {
	moduleName: string;
	jsonType: string;
	dynamicJSON: any;
	dynamicJSONValues: any;
	reloadOptions: boolean;
}

const initialState: IDynamic = {
	moduleName: '',
	jsonType: 'live',
	dynamicJSON: null,
	dynamicJSONValues: null,
	reloadOptions: false,
};

const dynamicStore = (state: any = initialState, action: any): IDynamic => {
	switch (action.type) {
		case SET_DYNAMIC_JSON_TYPE:
			return { ...state, jsonType: action.payload };
		case SET_DYNAMIC_JSON:
			return { ...state, dynamicJSON: action.payload };
		case SET_DYNAMIC_JSON_VALUES:
			return { ...state, dynamicJSONValues: action.payload };
		case SET_DYNAMIC_JSON_MODULE_NAME:
			return { ...state, moduleName: action.payload };
		case RELOAD_FIELD_OPTIONS_LIST:
			return { ...state, reloadOptions: action.payload };

		default:
			return state;
	}
};

export default dynamicStore;
